import React, { useState, useEffect } from "react"
import { Button, Space, Form, Row, Col, Input } from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons"
import { AppraisalTargetClient, AppraisalCycleClient } from "@api"
import {
  showSuccess,
  showError,
  DownloadConfigData,
  errorMessage,
} from "@action"
import { navigate } from "gatsby"
import UploadModal from "@components/UploadModal"
import FormModal from "@components/FormModal"
import { PanelTable, Loading, DeleteButton } from "lib"

const SearchForm = ({ oldSearchParams, setSearchParams }) => {
  const [form] = Form.useForm()
  const onFinish = values => {
    setSearchParams({
      ...oldSearchParams,
      pageIndex: 0,
      staffNo: values.staffNo,
      staffName: values.staffName,
    })
  }
  return (
    <Row gutter={24} className="admin_table">
      <Form layout="inline" onFinish={onFinish} form={form}>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a staff number" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a staff name" allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

const EditModal = ({
  id,
  isCreate,
  isNew,
  visible,
  onCancel,
  record,
  refresh,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue(record)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new AppraisalTargetClient()
    try {
      if (isCreate) {
        await client.create({ ...values, appraisalTargetSetId: id })

        showSuccess("Add Request Success")
      } else {
        await client.edit(record.id, { id: record.id, ...values })
        showSuccess("Edit Request Success")
      }
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Target" : "Edit Target", err)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Appraisal Target"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="staffNo"
          label="Staff Number"
          rules={[{ required: true, message: "Please input a staff number" }]}
        >
          <Input placeholder="Input a staff number" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const TargetTable = ({ id, isStart, appraisalCycleId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [visible, setVisible] = useState(false)
  const [uploadVisible, setUploadVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    id: id,
    pageSize: 10,
    pageIndex: 0,
    orderBy: "staffNo",
    isAscend: true,
    staffNo: null,
    staffName: null,
  })
  const [pageMeta, setPageMeta] = useState(undefined)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })

  const getData = async params => {
    setIsLoading(true)
    let client = new AppraisalTargetClient()
    try {
      let result = await client.search(
        params.id,
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName
      )
      setPageMeta(result.content.paginationMetadata)
      setDataSource(result.content.appraisalTargetGroup)
      setIsLoad(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateAppraisal = async id => {
    try {
      setIsLoading(true)
      const client = new AppraisalCycleClient()
      const appraisadlId = await client.createAppraisal({
        appraisalCycleId: appraisalCycleId,
        appraisalTargetId: id,
      })
      setIsLoad(true)
      if (appraisadlId) {
        showSuccess("Add Appraisal Success")
      }
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new AppraisalTargetClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Staff Number",
      dataIndex: "staffNo",
      sorter: true,
    },
    { title: "Staff Name", dataIndex: "staffName", sorter: true },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="small">
          {record.appraisalId === 0 ? (
            <Button
              shape="round"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                handleCreateAppraisal(record.id)
              }}
            >
              {isStart ? "Start Appraisal" : "Add"}
            </Button>
          ) : (
            <Button
              shape="round"
              icon={<SearchOutlined />}
              onClick={() => {
                navigate(`/appraisal/${record.appraisalId}`)
              }}
            >
              View
            </Button>
          )}
            <DeleteButton
              size="Middle"
              onClick={() => {
                deleteData(record.id)
              }}
            />
        </Space>
      ),
    },
  ]

  return isLoad ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
      />
      <EditModal
        id={id}
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        record={modalVars.record}
        visible={visible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <UploadModal
        setId={id}
        title="Target"
        visible={uploadVisible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setUploadVisible(false)
        }}
        apiClient={AppraisalTargetClient}
      />
      <Space size="small" style={{ marginBottom: "10px" }}>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>

        <Button
          shape="round"
          icon={<UploadOutlined />}
          onClick={() => {
            setUploadVisible(true)
          }}
        >
          Upload
        </Button>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => {
            DownloadConfigData(AppraisalTargetClient, id, false, false)
          }}
        >
          Download
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default TargetTable
